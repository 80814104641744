<div class="row">

  <div *ngIf="load" class="pt-5">
    <div align="center" class="load-setting">
      <i class="fa fa-spinner fa-pulse fa-4x fa-fw"></i>
    </div>
  </div>
  <form class="col-12 col-md-12" [formGroup]="form" (ngSubmit)="onSubmit();" novalidate>
    <div class="modal-body row shadow-sm  p-3 mb-5 bg-white rounded box-form">
      <div class="col-12 col-md-6 form-item pt-3">
        <div>
          <label class="pl-3" [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">Hotel or Apart Name</label>
          <input class="form-control text-field" placeholder="e.g: Amadeo Hotel " type="text" formControlName="Hotel"
            required>
          <div *ngIf="form.controls.Hotel.invalid && (form.controls.Hotel.dirty || form.controls.Hotel.touched)">
            <span class="s-sm booking" *ngIf="form.controls.Hotel.errors.required">
              Please insert a name.
            </span>
            <span class="s-sm booking"
              *ngIf="form.controls.Hotel.errors.minlength || form.controls.Hotel.errors.maxlength">
              The name has to have 2-60 characters
            </span>
          </div>

        </div>
      </div>
      <div class="col-12 col-md-6 form-item pt-3">
        <div>
          <label class="pl-3" [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">Phone Number</label>
          <input class=" form-control text-field" placeholder="e.g: +57822457898" type="text"
            formControlName="PhoneNumber" required>
          <div
            *ngIf="form.controls.PhoneNumber.invalid && (form.controls.PhoneNumber.dirty || form.controls.PhoneNumber.touched)">
            <span class="s-sm booking" *ngIf="form.controls.PhoneNumber.errors.required">
              Please insert a phone number.
            </span>
            <span class="s-sm booking" *ngIf="form.controls.PhoneNumber.errors.pattern">
              Insert a valid phone number.
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 form-item pt-2">
        <div>
          <label class="pl-3" [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">Contact</label>
          <input class="form-control text-field" placeholder="Name" type="text" formControlName="Contact" required>
          <div *ngIf="form.controls.Contact.invalid && (form.controls.Contact.dirty || form.controls.Contact.touched)">
            <span class="s-sm booking" *ngIf="form.controls.Contact.errors.required">
              Please insert a contact name.
            </span>
            <span class="s-sm booking"
              *ngIf="form.controls.Contact.errors.minlength || form.controls.Contact.errors.maxlength">
              The name has to have 2-60 characters
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 form-item pt-2">
        <div>
          <label class="pl-3" [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">Email Contact </label>
          <input class="form-control text-field" placeholder="e.g: calrissian@domain.com" type="email"
            formControlName="EmailContact" required>
          <div
            *ngIf="form.controls.EmailContact.invalid && (form.controls.EmailContact.dirty || form.controls.EmailContact.touched)">
            <span class="s-sm booking" *ngIf="form.controls.EmailContact.errors.required">
              Please insert an email.
            </span>
            <span class="s-sm booking" *ngIf="form.controls.EmailContact.errors.pattern">
              Insert a valid email.
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 form-item pt-2">
        <div>
          <label class="pl-3" [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">Country</label>
          <select class="form-control text-field" formControlName="Country" required>
            <option value=""> Select a country</option>
            <option *ngFor="let co of countries" value="{{co}}"> {{co}}</option>
          </select>

          <div *ngIf="form.controls.Country.invalid && (form.controls.Country.dirty || form.controls.Country.touched)">
            <span class="s-sm booking" *ngIf="form.controls.Country.errors.required">
              Please insert a country.
            </span>
            <span class="s-sm booking" *ngIf=" form.controls.Country.errors.maxlength">
              The country has a max of 50 characters
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 form-item pt-2">
        <div>
          <label class="pl-3" [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">City </label>
          <input class="form-control text-field" placeholder="e.g: Buenos Aires" type="text" formControlName="City"
            required>
          <div *ngIf="form.controls.City.invalid && (form.controls.City.dirty || form.controls.City.touched)">
            <span class="s-sm booking" *ngIf="form.controls.City.errors.required">
              Please insert a city.
            </span>
            <span class="s-sm booking" *ngIf=" form.controls.City.errors.maxlength">
              The city has a max of 50 characters
            </span>
          </div>

        </div>
      </div>

      <div class="col-12 col-md-6 form-item pt-2">
        <div>
          <label class="pl-3" [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}"> Which API are you connected to?</label>
          <select id="api-select-1" multiple="multiple">
          </select>
        </div>
      </div>
      <div id="sel-2" class="col-12 col-md-6 form-item pt-2">
        <div>
          <label class="pl-3" [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">Which API would you like to be connected to?</label> <br>
          <select id="api-select-2" multiple="multiple">
          </select>
        </div>
      </div>

      <div class="col-12 form-item  pb-5">
        <div>
          <label class="pl-3" [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">Any Comments?</label>
          <textarea class="form-control text-field" style="height:150px;" formControlName="Comments"
            placeholder="how did you find us?, any thoughts?"></textarea>
          <div
            *ngIf="form.controls.Comments.invalid && (form.controls.comments.dirty || form.controls.Comments.touched)">
            <span class="s-sm booking" *ngIf=" form.controls.Comments.errors.maxlength">
              The comments has a max of 200 characters
            </span>
          </div>
        </div>
      </div>

    </div>
    <div class="row p-3 leyend rounded rounded-2">
      <div class="col-12 col-md-9 pt-3 pl-3">
        <p class="f-justify" class="font-parrafo-white pr-3">
          Completar todos los campos, nos permitirá gestionar mas rápido la configuración del usuario para acceder al sistema. Para ser parte de la suite tecnológica de Moebius debes ser una Operador Mayorista constituido legalmente en tu país con las habilitaciones correspondiente.  Una vez aceptada la solicitud recibirás un mail con las indicaciones para acceder a la suite tecnológica.
        </p>
      </div>
      <div class="col-12 col-md-3 form-item pt-3 text-center">
        <input type="submit" class="btn-button s-l btn-leyend" value="ENVIAR" style="width: 90% !important;"
          [disabled]="!form.valid" />
      </div>
    </div>
  </form>
</div>
