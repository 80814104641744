
<div class="row">

<div *ngIf="load" align="center" style="width:100%; height:100%; position:absolute; left:0px; top:0px; z-index:2; background-color: #ffffff91">
	<br><br><br>
	<!-- <fa name="spinner" size='4x' animation="spin"> </fa> -->
		<br>
		<br>
		<p class="s-m"> Loading... </p>
</div>

<form class="col-12 col-md-12" [formGroup]="form" (ngSubmit)="onSubmit();" novalidate>
	  <div class="modal-body row shadow-sm  p-3 mb-5 bg-white rounded box-form">
	    <div class="col-12 col-md-6 form-item pt-3">
			<div>
				<label class="pl-3" [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">Nombre de la agencia</label>
				<input class="form-control text-field" placeholder="e.g: Amadeo Agency Tours" type="text"  formControlName="TravelAgencyName" required>
				<div *ngIf="form.controls.TravelAgencyName.invalid && (form.controls.TravelAgencyName.dirty || form.controls.TravelAgencyName.touched)">
					<span class="s-sm booking" *ngIf="form.controls.TravelAgencyName.errors.required"   >
						Por favor inserte un nombre
					</span>
					<span class="s-sm booking" *ngIf="form.controls.TravelAgencyName.errors.minlength || form.controls.TravelAgencyName.errors.maxlength">
						El nombre debe tener entre 2-60 carácteres
					</span>
				</div>
			</div>
	    </div>
	    <div class="col-12 col-md-6 form-item pt-3">
			<div>
				<label class="pl-3"  [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">Sitio web</label>
				<input class="form-control text-field" placeholder="e.g: http://booking.com" type="text" name="Website" formControlName="Website" required>
				<div *ngIf="form.controls.Website.invalid && (form.controls.Website.dirty || form.controls.Website.touched)">
					<span class="s-sm booking" *ngIf="form.controls.Website.errors.required">
						Por favor inserte un sitio web
					</span>
					<span class="s-sm booking" *ngIf="form.controls.Website.errors.pattern">
						Inserte una dirección valida
					</span>
				</div>
			</div>
	    </div>
	    <div class="col-12 col-md-6 form-item pt-2">
			<div>
				<label class="pl-3"  [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">Contacto comercial</label>
				<input class="form-control text-field" placeholder="Name" type="text" formControlName="Contact" required>
				<div *ngIf="form.controls.Contact.invalid && (form.controls.Contact.dirty || form.controls.Contact.touched)">
					<span class="s-sm booking" *ngIf="form.controls.Contact.errors.required">
						Por favor inserte un nombre
					</span>
					<span class="s-sm booking" *ngIf="form.controls.Contact.errors.minlength || form.controls.Contact.errors.maxlength">
						El nombre debe tener entre 2-60 carácteres
					</span>
				</div>
			</div>
	    </div>
	    <div class="col-12 col-md-6 form-item pt-2">
			<div>
				<label  class="pl-3"  [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">Email de contacto </label>
				<input class="form-control text-field" placeholder="e.g: calrissian@domain.com" type="email"  formControlName="EmailContact" required>
				<div *ngIf="form.controls.EmailContact.invalid && (form.controls.EmailContact.dirty || form.controls.EmailContact.touched)">
					<span class="s-sm booking" *ngIf="form.controls.EmailContact.errors.required">
						Por favor inserte un email
					</span>
					<span class="s-sm booking" *ngIf="form.controls.EmailContact.errors.pattern">
						Inserte un email valido
					</span>
				</div>
			</div>
	    </div>
	    <div class="col-12 col-md-6 form-item pt-2">
			<div>
				<label  class="pl-3"  [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">País</label>
				<select class="form-control text-field" formControlName="Country" required >
					<option value="" > Selecciona un país</option>
					<option *ngFor="let co of countries" value="{{co}}" > {{co}}</option>
				</select>

				<div *ngIf="form.controls.Country.invalid && (form.controls.Country.dirty || form.controls.Country.touched)">
					<span class="s-sm booking" *ngIf="form.controls.Country.errors.required">
						Por favor inserte un país
					</span>
					<span class="s-sm booking" *ngIf=" form.controls.Country.errors.maxlength">
						The country has a max of 50 characters
					</span>
				</div>
			</div>
	    </div>
	    <div class="col-12 col-md-6 form-item pt-2">
			<div>
				<label  class="pl-3"  [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">Ciudad </label>
				<input class="form-control text-field" placeholder="e.g: Buenos Aires" type="text"  formControlName="City" required>
				<div *ngIf="form.controls.City.invalid && (form.controls.City.dirty || form.controls.City.touched)">
					<span class="s-sm booking" *ngIf="form.controls.City.errors.required">
						Por favor inserte un ciudad
					</span>
					<span class="s-sm booking" *ngIf=" form.controls.City.errors.maxlength">
						La ciudad debe tener un máximo de 50 cáracteres
					</span>
				</div>

			</div>
	    </div>
	    <div class="col-12 col-md-4 form-item pt-2">
			<div>
				<label class="pl-3"  [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">Número telefónico</label>
				<input class=" form-control text-field" placeholder="e.g: +57822457898" type="text" n formControlName="PhoneNumber" required>
				<div *ngIf="form.controls.PhoneNumber.invalid && (form.controls.PhoneNumber.dirty || form.controls.PhoneNumber.touched)">
					<span class="s-sm booking" *ngIf="form.controls.PhoneNumber.errors.required">
						Por favor inserte un número telefónico
					</span>
					<span class="s-sm booking" *ngIf="form.controls.PhoneNumber.errors.pattern">
						Inserte un número de telefóno valido (ejemplo: +54)
					</span>
				</div>
			</div>
	    </div>
	    <div class="col-12 col-md-4 form-item pt-2">
			<div>
				<label  class="pl-3"  [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}"> Porcentaje de viajes a USA</label>
				<select class="form-control text-field"  formControlName="PercentageTravels" required >
					<option value="" > Seleccione un rango</option>
					<option value="10-20" > 10% - 20% </option>
					<option value="20-30"> 20% - 30% </option>
					<option value="40-50"> 40% - 50% </option>
					<option value="+50"> + 50% </option>
				</select>
				<div *ngIf="form.controls.PercentageTravels.invalid && (form.controls.PercentageTravels.dirty || form.controls.PercentageTravels.touched)">
					<span class="s-sm booking" *ngIf="form.controls.PercentageTravels.errors.required">
						Por favor seleccione un rango
					</span>
				</div>
			</div>
	    </div>
	    <div class="col-12 col-md-4 form-item pt-2">
			<div>
				<label  class="pl-3"  [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">No. de empleados</label>
				<select class="form-control text-field" formControlName="NumberEmployee" required >
					<option value="" > Seleccione un número</option>
					<option *ngFor="let number of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19]" value="{{number}}" > {{number}}</option>
					<option value="20"> +20 </option>
				</select>
				<div *ngIf="form.controls.NumberEmployee.invalid && (form.controls.NumberEmployee.dirty || form.controls.NumberEmployee.touched)">
					<span class="s-sm booking" *ngIf="form.controls.NumberEmployee.errors.required">
						Por favor seleccione un número
					</span>
				</div>
			</div>
	    </div>
	    <div class="col-12 form-item pt-2 pb-5">
			<div>
				<label  class="pl-3"  [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">¿Algún comentario?</label>
				<textarea class="form-control text-field" style="height:150px;" formControlName="Comments" placeholder="¿Cómo nos encontraste?, ¿alguna idea?"  ></textarea>
				<div *ngIf="form.controls.Comments.invalid && (form.controls.Comments.dirty || form.controls.Comments.touched)">
					<span class="s-sm booking" *ngIf=" form.controls.Comments.errors.maxlength">
						Su comentario debe contener como máximo 200 carácteres
					</span>
				</div>
			</div>
		</div>
	  </div>
	  <div class="row p-3 leyend rounded rounded-2">
	  	<div class="col-12 col-md-9 pt-3 pl-3">
			<p class="f-justify" class="font-parrafo-white pr-3">
				Completar todos los campos, nos permitirá gestionar mas rápido la configuración del usuario para acceder al sistema. Para ser parte de la suite tecnológica de Moebius debes ser una Operador Mayorista constituido legalmente en tu país con las habilitaciones correspondiente.  Una vez aceptada la solicitud recibirás un mail con las indicaciones para acceder a la suite tecnológica.
			</p>
		</div>
		<div class="col-12 col-md-3 form-item pt-3 text-center">
			<input type="submit" class="btn-button s-l btn-leyend" value="SEND"  style="width: 90% !important;" [disabled]= "!form.valid"/>
		</div>
	  </div>

</form>


</div>


