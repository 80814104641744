<form [formGroup]="changePassForm" *ngIf="formChangePass">
  <div class="row flex-column pb-2 align-items-end container-form">
    <div class="col-12">
      <label class="text-white">
        Contraseña nueva
      </label>
      <div class="password-input">
        <input
          class="form-control"
          placeholder="●●●●●●●"
          [type]="showPassword ? 'text' : 'password'"
          name="password"
          id="password"
          formControlName="password"
          required
        >
        <button class="password-toggle-btn" (click)="togglePasswordVisibility()">
          <i class="fa" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
        </button>
      </div>
        <span class="s-sm booking" *ngIf="changePassForm.controls.password.errors?.minlength">
          La contraseña debe tener al menos 6 caracteres.
        </span>
    </div>
    <div class="col-12 mt-1">
      <label class="text-white">
        Repita la contraseña
      </label>
      <div class="password-input">
        <input
          class="form-control"
          placeholder="●●●●●●●"
          [type]="showPasswordConfirm ? 'text' : 'password'"
          name="confirmPassword"
          id="confirmPassword"
          formControlName="confirmPassword"
          required
        >
        <button class="password-toggle-btn" (click)="togglePasswordConfirmVisibility()">
          <i class="fa" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
        </button>
      </div>
      <span class="s-sm booking" *ngIf="changePassForm.controls.confirmPassword.value !== changePassForm.controls.password.value && changePassForm.controls.confirmPassword.touched">
        Las contraseñas no coinciden.
      </span>
    </div>
    <div>
      <button
        type="button"
        class="btn border-white btn-send mt-4"
        style="margin-right: 1em;"
        (click)="onClick(); onSubmit()"
        [disabled]="!changePassForm.valid || changePassForm.controls.confirmPassword.value !== changePassForm.controls.password.value"
      >
        Enviar
      </button>
      <button
      type="button"
      class="btn border-white btn-send mt-4"
      style="margin-right: 1em;"
      (click)="closeChangePass()"
    >
      Cerrar
    </button>
  </div>
  </div>
</form>

<ng-container *ngIf="messageChangePass">
  <div class="messageChange">
    {{message}}
  </div>
</ng-container>
