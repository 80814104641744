<!-- <footer class="footer-content py-6" [ngStyle]="{'background-image': 'url(&quot;' + routeUploadFile+sectionFooter?.background_image + '&quot;)'}"> -->
<footer class="footer-content" [ngStyle]="{ 'background-image': isHome ? 'url(\'' + routeUploadFile + sectionFooter?.background_image + '\')' : 'none',
  'background-color': !isHome ? 'white' : 'none'}"
  [ngClass]="{ 'footer-content-home': isHome, 'footer-content-select': !isHome }">

  <div class="row footer-top-inner-vv footer-box container"
    [ngClass]="{ 'footer-box-home': isHome, 'footer-box-select': !isHome}">
    <div class="col-md-4 col-sm-12 col-12  mt-lg-0 mt-4 py-2 footer-logo">

      <img class="float-xs-left logo" src="{{ routeUploadFile+getClientsSettingAppAuth?.logo_path_three }}"
        align="center" />
      <br>
      <div class="mt-4">
        <a class="subtitle-logo-footer ">{{sectionFooter?.info.title}}</a>
        <p class="pt-1 mr-5 pr-5 text-footer">
          {{sectionFooter?.info.title_sub}}
        </p>

        <div class="row mr-5 pr-5 mr-5 pl-0" id="set-icon-footer">
          <div class="col-md-1 col-sm-12 col-12  mt-lg-0 mt-4 py-2">
            <a href="https://wa.me/5454115031200" target="_blank" rel="noopener noreferrer">
              <i aria-hidden="true" class="icon-whatsapps"></i>
            </a>
          </div>
          <div class="col-md-1 col-sm-12 col-12  mt-lg-0 mt-4 py-2 ml-3">
            <a href="https://www.instagram.com/moebiusdmc/" target="_blank" rel="noopener noreferrer">
              <i aria-hidden="true" class="icon-instagrams"></i>
            </a>
          </div>
          <div class="col-md-1 col-sm-12 col-12  mt-lg-0 mt-4 py-2 ml-3">
            <a href="https://www.linkedin.com/company/moebius-dmc/" target="_blank" rel="noopener noreferrer">
              <i aria-hidden="true" class="icon-links"></i>
            </a>
          </div>
          <div class="col-md-1 col-sm-12 col-12  mt-lg-0 mt-4 py-2 ml-3">
            <a href="#" target="_blank" rel="noopener noreferrer">
              <i aria-hidden="true" class="icon-facebooks"></i>
            </a>
          </div>
          <div class="col-md-8 col-sm-12 col-12  mt-lg-0 mt-4 py-2 ml-3">

          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8 col-sm-6 mt-lg-0 mt-4 py-4" id="footer-info">
      <div class="row pl-0 flex-nowrap" id="footer-title-links">
        <div class="col-md-2 col-sm-6 mt-lg-0 mt-4 ">
          <div class="footer-lavi">
            <h3 class="mb-3 footer_title">COMPAÑÍA</h3>
            <ul class="footer-list-info pt-3">
              <li>
                <a href="#about">
                  Misión
                </a>
              </li>
              <li>
                <a href="#gallery">
                  Valores
                </a>
              </li>
              <li>
                <a href="#">
                  Equipo
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 mt-lg-0 mt-4">
          <div class="footer-lavi ">
            <h3 class="mb-3 footer_title">HERRAMIENTAS</h3>
            <ul class="footer-list-info  pt-3">
              <li>
                <a href="#about">
                  Flyers
                </a>
              </li>
              <li>
                <a href="#gallery">
                  Redes Sociales
                </a>
              </li>
              <li>
                <a href="#">
                  Newsletter
                </a>
              </li>
              <li>
                <a href="#contact">
                  Capacitación
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-2 col-sm-6 mt-lg-0 mt-4" id="element-product-footer">
          <div class="footer-lavi">
            <div class="footer-lavi ">
              <h3 class="mb-3 footer_title">PRODUCTOS</h3>
              <ul class="footer-list-info  pt-3">
                <li>
                  <a href="#about">
                    Destinos
                  </a>
                </li>
                <li>
                  <a href="#gallery">
                    Circuitos
                  </a>
                </li>
                <li>
                  <a href="#">
                    Hoteles
                  </a>
                </li>
                <li>
                  <a href="#contact">
                    Tours
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-sm-6 mt-lg-0 mt-4">
          <div class="footer-lavi">
            <div class="footer-lavi ">
              <h3 class="mb-3 footer_title">SEGMENTOS</h3>
              <ul class="footer-list-info  pt-3">
                <li>
                  <a href="#about">
                    Leisure
                  </a>
                </li>
                <li>
                  <a href="#gallery">
                    Luxury
                  </a>
                </li>
                <li>
                  <a href="#">
                    Mice y Grupos
                  </a>
                </li>
                <li>
                  <a href="#contact">
                    Wellnes
                  </a>
                </li>
                <li>
                  <a href="#">
                    Deportes
                  </a>
                </li>
                <li>
                  <a href="#contact">
                    Bodas
                  </a>
                </li>
                <li>
                  <a href="#">
                    Corporativo
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 mt-lg-0 mt-4">
          <div class="footer-lavi">
            <div class="footer-lavi ">
              <h3 class="mb-3 footer_title">CONTACT US</h3>
              <ul class="footer-list-info  pt-3">
                <li>
                  <a href="#about">
                    ARG: +54 11 50312004
                  </a>
                </li>
                <li>
                  <a href="#gallery">
                    ING: +44 20 37693488
                  </a>
                </li>
                <li>
                  <a href="#">
                    ESP: +34 911 516775
                  </a>
                </li>
                <li>
                  <a href="#contact">
                    BRZ: +55 11 31817440
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-dowp container"
      [ngClass]="{ 'footer-dowp-home' : isHome, 'footer-dowp-select' : !isHome }"
      [ngStyle]="{'background-color':getClientsSettingAppAuth?.clients_setting_global?.color_main_2}"> <!--Luego borrar-->
  </div>
  </div>
  <div class="container mx-auto" id="adjust-footer">
  </div>
  <div id="footer-space" [ngStyle]="{'margin-bottom': isHome ? '400px' : '0px'}"></div>
  <div class="row" id="adjust-footer">
  </div>

  <!-- //footer bottom -->
</footer>
