import { Component, EventEmitter, Input, Output  } from '@angular/core';
//import { ToastsManager } from 'ng2-toastr/ng2-toastr';
//import { Logger } from 'angular2-logger/core';

import { ResetpassForm } from './resetpass';
import { ResetpassService } from './resetpass.service';
import { setting } from '../../../../setting';

//  Jquery imports
// import * as jquery from 'jquery';
declare var jquery:any;
declare var $:any;

@Component({
	selector: 'resetpass',
	templateUrl: './resetpass.component.html',
	providers: [ResetpassService]
})

export class ResetpassComponent {
	public resetForm : ResetpassForm;
	public resetError : ResetpassForm;
	public load : boolean;
  public getClientsSettingAppAuth: any;
	@Output() finished = new EventEmitter<boolean>();

	constructor(
		private _resetpassService: ResetpassService,
		//public toastr: ToastsManager,
	){
		this.resetForm = new ResetpassForm("");
		this.resetError = new ResetpassForm("");
		this.load = false;
    this.getClientsSettingAppAuth =  JSON.parse((localStorage.getItem(setting.name)));
	}


	getFinished() {
		return this.finished;
	}

	onReset() {
		this.load = true; // loading screen

		//this.logger.log(this.resetForm);

		this._resetpassService.resetpass(this.resetForm).subscribe(
			(data:any) => {
				if(data.res == 1){
					//this.toastr.success( "An email has been sent to change your password",'Success!');
					this.finished.emit(true);
				} else {
					//this.toastr.error( data.det,'Error');
				}

				//this.logger.log(data);
				this.load = false;
			},
			error => {
  				//this.toastr.error(JSON.parse(error._body).det,'Error');
				this.load = false;
				//this.logger.log(error);
			}
		);

	}

}
