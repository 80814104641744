<form  #formRecover="ngForm" (ngSubmit)=" onReset();">
	<div *ngIf="!load">

		<div class="row pb-2">
				<div class="col-12 col-md-5">
					<input class="form-control" placeholder="e.g: calrissian@domain.com" type="email" email name="resetEmail" #resetEmail="ngModel" [(ngModel)]="resetForm.email" required>
					<p class="pt-3 font-parrafo">Enter mail´s address with which you register and  we will send you acces data. </p>
				</div>
				<div class="col-12 col-md-3  pl-0">
					<input class="btn-button s-l" style="width: 80% !important;"  type="submit" value="SEND" [disabled]="!formRecover.form.valid" >
					<!-- <p class="s-sm white" *ngIf="recover_msg"> {{recover_msg}}  </p> -->
				</div>
				<div class="col-12 col-md-1 ">
					<p class="separator-point-green" [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}"> </p>
				</div>
				<div class="col-12 col-md-3 text-right ">
					<input class="btn-green s-l"  type="submit" value="REGISTER HERE" [disabled]="!formRecover.form.valid" [ngStyle]="{'background-color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}"
            style="color: #000000 !important; border:none">
					<p class="pt-3 font-parrafo-grenn" [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">If you are not registered</p>
				</div>

				<!-- ERRORS -->
					<div *ngIf="resetEmail.invalid && (resetEmail.dirty || resetEmail.touched)">
						<span class="s-sm booking pl-3" *ngIf="resetEmail.errors.required">
							Please insert an email.
						</span>
						<span class="s-sm booking pl-3" *ngIf="resetEmail.errors.email">
							Please insert a valid email.
						</span>
				</div>

		</div>

	</div>
	<div *ngIf="load">

		<div align="center">
			<!-- <fa name="spinner" size='4x' animation="spin"> </fa> -->
	 		<br>
	 		<br>
	 		<p class="s-m"> Loading... </p>
		</div>

	</div>
</form>
