import { Component, EventEmitter, Output  } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from "ngx-toastr";
import { SignupService } from '../signup.service';
import { setting } from '../../../../../setting';

declare var jquery:any;
declare var $:any;

@Component({
	selector: 'agencies-form',
	templateUrl: './agencies.component.html',
	providers: [SignupService]
})

export class AgencyComponent {
  @Output() successEvent = new EventEmitter<void>();
	public load : boolean;
	public form : FormGroup;
	public countries : string[];
 	 public getClientsSettingAppAuth: any;

	constructor(
		private _signupService: SignupService,
		private toastr: ToastrService,
    	private fb : FormBuilder
	){
    this.getClientsSettingAppAuth = JSON.parse(localStorage.getItem(setting.name));
		this.load = false;
		this.form = this.fb.group({
			Youaretype: [1, [Validators.required] ],
			TravelAgencyName : ['', [Validators.required, Validators.minLength(2), Validators.maxLength(60)] ],
			City: ['', [Validators.required, Validators.maxLength(50)] ],
			Country: ['', [Validators.required,Validators.maxLength(50)] ],
			PhoneNumber: ['', [Validators.required, Validators.pattern('[+]{1}[0-9]{10,16}')]],
			Website: ['', [Validators.required, Validators.pattern("(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+")]],
			Contact: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(60)] ],
			EmailContact: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
			PercentageTravels: ['',[Validators.required]],
			NumberEmployee: ['', [Validators.required] ],
			Comments: ['', [Validators.maxLength(200)] ]
	    });

	    this.countries = [
	    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Aruba", "Australia",  "Austria", "Azerbaijan",
	    "Bahamas", "Bahrain",  "Bangladesh",  "Barbados",  "Belarus", "Belgium", "Belize", "Benin", "Bermuda", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi",
	    "Cambodia", "Cameroon", "Canada", "Cape Verde", "Central African Republic", "Chad", "Chile", 'Cook Islands', "Colombia", "Comoros", "Costa Rica", "Côte d'Ivoire", "Croatia", "Cuba", "Czech Republic",
	    "Democratic Republic of the Congo" , "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Democratic Republic of Congo",
	    "East Timor", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Ethiopia",
	    "Federated States of Micronesia", "Fiji", "Finland", "France", "Gabon", "The Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hungary",
	    "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy", "Ivory Coast", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Kuwait", "Kyrgyzstan",
	    "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico","Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco","Mozambique","Myanmar",
	    "Namibia", "Nauru", "Nepal","Netherlands","New Zealand","Nicaragua","Niger","Nigeria","Niue","North Korea","Norway", "Oman", "Pakistan","Palau","Panama", "Papua New Guinea", "Paraguay","People's Republic of China", "Peru","Philippines","Poland","Portugal",
	    "Qatar", "Republic of Macedonia", "Republic of the Congo", "Republic of Cyprus", "Romania", "Russia","Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", "São Tomé and Príncipe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Korea",
	    "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Swaziland", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates","United Kingdom", "United States", "Uruguay", "Uzbekistan",
	    "Vanuatu","Venezuela","Vietnam", "Yemen", "Zambia", "Zimbabwe"
	    ];

	}

	onSubmit() {
		this.load = true; // loading screen

		this.form.value.NumberEmployee = parseInt(this.form.value.NumberEmployee);
		//this.logger.log(this.form.value);
		this._signupService.signup(this.form.value).subscribe(
			(data:any) => {
        console.log(data)
				this.load = false;
				if(data.res == 1) {
          this.toastr.success(data.det);
          // this.successEvent.emit(); // Emitir el evento de éxito
					this.resetform();

				}else {
				}
			},
			error => {
				this.load = false;
				this.toastr.error(JSON.parse(error._body).det,'Error');
			}
		);

	}

	resetform(){
		this.form.setValue({
			Youaretype: 1,
			TravelAgencyName: '',
			City: '',
			Country: '',
			PhoneNumber: '',
			Website: '',
			Contact: '',
			EmailContact: '',
			PercentageTravels: '',
			Comments: '',
			NumberEmployee: ''
		});
	}

}
