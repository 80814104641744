import { HttpClient, HttpHeaders , HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import { setting } from '../../../setting';

@Injectable()

export class HomeService {
	public apiBTIUrl: string;
	public apiBookingUrl  : string;
	constructor(private _http: HttpClient) {
		this.apiBTIUrl = setting.apiBTIUrl;
		this.apiBookingUrl  = setting.apiBookingUrl
	}

	getClientsSettingAppAuth() {
		let params: HttpParams = new HttpParams();
		params = params.set('name', setting.name);
		console.log("URL getClientsSettingAppAuth'", this.apiBTIUrl + 'getClientsSettingAppAuth');
		return this._http.get(this.apiBTIUrl + 'getClientsSettingAppAuth', { params: params });
	}
}
