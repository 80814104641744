import { NgModule }              from '@angular/core';
import { RouterModule, Routes }  from '@angular/router';


import { HomeComponent } from './pages/home.component';
import { SelectComponent } from './pages/select/select.component';
import { AppComponent } from './app.component';
import { ArticlesComponent } from './pages/content-articles/articles.component';


const appRoutes: Routes = [

    {
      path: 'select',
      component: SelectComponent
    },
    {
      path: 'articles',
      component: ArticlesComponent
    },
    {
      path: 'home/:redirect',
      component: HomeComponent
    },
    {
      path: '',
      redirectTo: 'home/',
      pathMatch: 'full'
    },
   {
      path: '**',
      redirectTo: 'home/',
      pathMatch: 'full'
    }
];


@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes // <-- debugging purposes only
, { relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}
