<header class="justify-items-around fixed-top navbar-expand-lg">
  <div id="section-top-header"
       class="row align-items-center pr-4 container-fluid"
  >
    <div
      class="d-flex justify-content-start text-truncate align-items-center align-self-center col-lg-8 col-md-12 col-12">
      <ul class=" nav justify-content-xg-end justify-content-lg-end">
        <li class="nav-item">
          <a href="https://wa.me/5454115031200"
             class="menu-sesion font-menu nav-link header-phone"
             target="_blank"
             rel="noopener noreferrer"
          >
            <i class="bi bi-whatsapp text-white"></i>
            &nbsp;{{ getClientsSettingAppAuth?.phone_number_one }}
          </a>
        </li>
        <li class="nav-item">
          <a href="#"
             class="menu-sesion font-menu nav-link header-phone">
            <i class="bi bi-telephone-fill text-white"></i>
            &nbsp;{{ getClientsSettingAppAuth?.wsp_phone_number }}
          </a>
        </li>
      </ul>
    </div>
    <div class="d-flex justify-content-end text-truncate align-self-center col-lg-4 col-md-12 col-12"
         id="icon-language"
    >
      <ul class=" nav justify-content-xg-end justify-content-lg-end"
          id="justify-icon-query">
        <li class="nav-item pr-3">
          <a href="#"
             class="font-menu nav-link bg-lang-active"
             [ngStyle]="{'background-color':getClientsSettingAppAuth?.clients_setting_global?.color_main_3}"
          >
            EN
          </a>
        </li>
        <li class="nav-item pr-3">
          <a href="#"
             class="font-menu nav-link bg-lang"
        >
          ES
        </a>
        </li>
        <li class="nav-item pr-0">
          <a href="#"
             class="font-menu nav-link bg-lang"
          >
            PT
          </a>
        </li>
      </ul>
    </div>
  </div>
    <div id="section-header"
         class="row header-top align-items-center"
         [ngStyle]="{'background-color': headerClass}" >

    <div class="d-flex justify-content-start text-truncate align-self-center col-lg-4 col-md-12 col-12 pl-0 pr-0">
      <button type="button"
              class="btn btn-sm ml-4 mb-5"
      >
        <i class="bi bi-list"
           style="font-size: 40px;"
           [ngStyle]="{
            'color': sectionHeader.icon_background,
            '-webkit-text-stroke': '2px ' + sectionHeader.icon_background
          }"
           *ngIf="showButton"
      >
        </i>
      </button>
    </div>
    <div class="d-flex justify-content-center col-xg-4 col-lg-4 col-md-7 col-10 p-0">
      <a
          href="#/home"
          class="row align-items-center">
        <div
          class="col-lg-12 col-md-2 col-1 justify-content-center"
          align="center"
        >
          <img [src]="logoPath"
               alt="Logo"
              class="d-block pt-2 logo"
          >
        </div>
      </a>
    </div>
    <div class="d-flex justify-content-end text-truncate align-self-center col-lg-4 col-md-12 col-12 mb-5 pb-2">

      <!-------------------  ROUTE HOME  ------------------------->

      <ul class=" nav justify-content-xg-end justify-content-lg-end" *ngIf="showHomeIcons" id="menu-right">
        <li class="nav-item">
          <a href="#" class="font-menu nav-link" style="margin-right: 1.5em;">
            <i class="bi bi-facebook"
              style="font-size: 23px;"
              [ngStyle]="{'color': sectionHeader.icon_background}"></i></a> <!---acá-->
        </li>
        <li class="nav-item mt-1">
          <a href="#help"
             class=" font-menu nav-link"
             [ngStyle]="{'color': sectionHeader.icon_background}"><i class="bi bi-grid-3x3-gap-fill"></i></a> <!---acá-->
        </li>
        <li class="nav-item mt-2 ml-3" style="transform: rotate(-45deg);">
          <i class="bi bi-slash-lg slash"></i>
        </li>
        <li class="nav-item mt-1 mr-1">
          <a href="https://wa.me/5454115031200" target="_blank" rel="noopener noreferrer" class=" font-menu nav-link"><i
              class="bi bi-whatsapp" style="stroke:black 3px; color: black"
              [ngStyle]="{'color': sectionHeader.icon_background, 'stroke': '2px ' + sectionHeader.icon_background}"></i></a><!---acá-->
        </li>
        <li class="nav-item mt-2" style="transform: rotate(-45deg);">
          <i class="bi bi-slash-lg slash"></i>
        </li>
        <li class="nav-item mt-1">
          <a href="#help" class=" font-menu nav-link" [ngStyle]="{'color': sectionHeader.icon_background}"><i class="bi bi-question-circle-fill"></i></a> <!---acá-->
        </li>
        <li class="nav-item mt-2" style="transform: rotate(-45deg);">
          <i class="bi bi-slash-lg"></i>
        </li>
        <li class="nav-item mt-1">
          <a href="#" class="font-menu nav-link" style="margin-right: 2em;"
          [ngStyle]="{'color': sectionHeader.icon_background}"><!---acá-->
            <i class="bi bi-gear-fill"></i>
          </a>
        </li>
      </ul>

      <!-------------------  ROUTE SELECT  ------------------------->

      <ul class="nav" style="height: 64px !important; margin-right: 50px;" *ngIf="showSelectIcons">
        <li class="nav-item items">
          <a class="font-menu nav-link datos-header-right items-distance"
            style="margin-top: .6em; margin-right: 1em;">{{ fechaa }}</a>
        </li>
        <li class="nav-item items" style="padding-top: 12px !important;">
          <i class="icon icon-separador items-distance" aria-hidden="true" style="font-size: 16px !important;"></i>
        </li>
        <li class="nav-item items">
          <a href="#" class="font-menu nav-link datos-header-right items-distance text-white"
            style="margin-top: .5em; margin-right: 1em;">Help</a>
        </li>
        <li class="nav-item items position-relative">
          <button type="button"
                  class="font-menu nav-link datos-header-right items-distance text-white btn btn-options lc-block"
                  style="margin-top: .6em; margin-right: .75em;"
                  (click)="toggleChangeOption()"
          ><!---acá-->
          <i class="bi bi-gear-fill"></i>
        </button>
        <ng-container *ngIf="changePassword">
          <div class="remember-change-password"
          >
            Recuerde cambiar la contraseña
          </div>
      </ng-container>
        </li>
        <li class="nav-item items">
          <button type="button" class="close" placement="bottom" style="margin-left: 2em;">
            <i class="icon-power" aria-hidden="true"  (click)="onLogout();"></i>
          </button>
        </li>
      </ul>
    </div>
  </div>


  <ng-container *ngIf="isOpenOption && !changePassModal">
    <div class="container-option">

      <p class=" mb-0"
          [ngStyle]="{'color': hoverColor || colorWhite}"
          (mouseenter)="setHoverColor(getClientsSettingAppAuth?.clients_setting_global?.color_main_3)"
          (mouseleave)="setHoverColor('')"
          (click)="openChangePass();"
      >Cambiar Contraseña</p>
    </div>
   </ng-container>

    <ng-container *ngIf="changePassModal">
      <div>

        <app-changepass class="containerModalChangePass"
                        [id]="id"
                        (fadeOutEvent)="fadeOutAnimation()"
                        (closeModal)="closeChangePassword()"
        >
        </app-changepass>
      </div>
    </ng-container>


</header>
