import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';

import { ResetpassForm } from './resetpass';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { setting } from '../../../../setting';

@Injectable()

export class ResetpassService {
	public apiBookingUrl:string;

	constructor(private _http:HttpClient){
		 this.apiBookingUrl = setting.apiBookingUrl;
	}

	resetpass(recover: ResetpassForm){
		let json = JSON.stringify(recover);
		let headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this._http.post(this.apiBookingUrl+'reset_forgot', json, {headers: headers});
	}

}
