export const setting= {
  name: "Usblick",
  production: false,
  c_domain: '.usblick.com',
  apiBTIUrl: 'https://auth-api.usblick.com/api/',
  appUrl: 'https://www.usblick.com/#/home/',
  authApiUrl : 'https://auth-api.usblick.com/api/',
  appBookingBackoffice: 'http://localhost:4580/',
  appBookingUrl: 'https://booking.usblick.com/',
  apiBookingUrl: 'https://booking-api.usblick.com/api/v1/',
  apiOctopusUrl: 'https://octopus-apis.com/api',
  routeUploadFile: 'https://auth-api.usblick.com/storage/app/public/',
  app_names: {
  	booking_backoffice: 'booking backoffice',
  	zoco: 'zoco',
  	booking: 'booking',
  	abaco: 'abaco',
  	chat: 'chat',
  	octopus: 'octopus'
  }
};