import { Component, Input, OnInit} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import * as moment from "moment";
import { HomeService } from "../../home.service";
import { setting } from "../../../../../setting";
import { LoginService } from "../../login/login.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  providers: [HomeService],
})
export class HeaderComponent implements OnInit {
  @Input() id: number;
  @Input() change_password: number;
  fechaa = moment().format("Do MMMM YYYY");
  menuHidden = true;
  isCollapsed = false;
  public getClientsSettingAppAuth: any;
  public sectionHeader: any;
  public routeUploadFile:any;
  headerClass: string = '';
  logoPath: string = '';
  showButton: boolean = true;
  showHomeIcons: boolean = true;
  showSelectIcons: boolean = false;
  public user: any;
  public isOpenOption: boolean = false;
  public hoverColor: string = '';
  public colorWhite: string = '#ffffff';
  public changePassModal: boolean = false;
  public isClickedInside: boolean = false;
  public changePassword: boolean = false;
  public documentClickHandler: any;
  fadeOut: boolean = false;

  constructor(private router: Router,
     private _homeService: HomeService,
     private _loginService: LoginService,
     public _router: Router,
     private toastr: ToastrService,
     ) {
    this.getClientsSettingAppAuth = JSON.parse(localStorage.getItem(setting.name));
    this.routeUploadFile =  setting.routeUploadFile;
    console.log("llegando a  section_header getClientsSettingAppAuth", this.getClientsSettingAppAuth);
    this.sectionHeader = JSON.parse(this.getClientsSettingAppAuth.clients_setting_app_auth.section_header);



/*------------- Instance change style, logo and icon of route  -----------------*/

this.headerClass = this.sectionHeader.background_1;
this.router.events.subscribe(event => {
  if (event instanceof NavigationEnd) {
    if (event.url === '/home') {
      this.headerClass = this.sectionHeader.background_1;
      } else if (event.url === '/select') {
      this.headerClass = this.sectionHeader.background_2;
    }
  }
});

  this.logoPath = this.routeUploadFile + this.sectionHeader.logotype_header_1;
  this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd) {
      if (event.url === '/home' ) {
        this.logoPath = this.routeUploadFile + this.sectionHeader.logotype_header_1;
      } else if (event.url === '/select') {
        this.logoPath = this.routeUploadFile + this.sectionHeader.logotype_header_2;
      }
    }
  });



      this.showButton = true;
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (event.url === '/select') {
            this.showButton = false;
          }
        }
      });

      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (event.url === '/select') {
            this.showHomeIcons = false;
            this.showSelectIcons = true;
          }
        }
    });

  }

  ngOnInit() {

    setTimeout(() => {
      if(this.change_password === 0) {
        this.changePassword = true;
      } else {
        this.changePassword = false;
      }
    }, 1500);

    console.log(this.sectionHeader.icon_background)




    this.documentClickHandler = (event: MouseEvent) => {
      const targetElement = event.target as HTMLElement;
      const containerElement = document.querySelector('.remember-change-password');

      if (containerElement && !containerElement.contains(targetElement)) {
        this.changePassword = false;
      }
    };

    document.addEventListener('click', this.documentClickHandler);
  }

  onLogout() {
    this.user =localStorage.getItem("user");
    let data = {
      email: this.user.email,
      auth_token: this._loginService.getCookie("auth_token"),
    };

    this._loginService.logout(data).subscribe(
      (data: any) => {
        if (data.res == 1) {
          this._loginService.setCookie("auth_token", "", -1, setting.c_domain);
          this.toastr.success("Hasta Pronto !!", "");
          this._router.navigate(["/home", {}]);
        } else {
          this._loginService.setCookie("auth_token", "", -1, setting.c_domain);
          this._router.navigate(["/home", {}]);
        }
      },
      (error) => {
        this.toastr.error(JSON.parse(error._body).det,'Error');
      }
    );
  }

  toggleChangeOption() {
    this.isOpenOption = !this.isOpenOption;
  }

  setHoverColor(color: string) {
    this.hoverColor = color;
  }

  openChangePass() {
    this.isOpenOption = true;
    this.changePassModal = true;
    this.isClickedInside = true;
  }

  closeChangePass() {
    this.changePassModal = false;
    this.isOpenOption = false;
    this.isClickedInside = false;
  }

  onClick() {
    if (!this.isClickedInside) {
      this.closeChangePass();
    }
    this.isClickedInside = false;
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.documentClickHandler);
  }

  fadeOutAnimation() {
    this.fadeOut = true;
    setTimeout(() => {
      this.fadeOutAnimationComplete();
    }, 3500);

  }

  fadeOutAnimationComplete() {
    this.changePassModal = false;
    this.changePassword = false;
    this.isOpenOption = false;
  }

  closeChangePassword() {
    this.changePassModal = false;
    this.changePassword = false;
    this.isOpenOption = false;
  }

}
