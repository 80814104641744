import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChangepassService } from './changepass.service';
import { LoginService } from '../login/login.service';
import { setting } from '../../../../setting';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-changepass',
  templateUrl: './changepass.component.html',
  styleUrls: ['./changepass.component.scss']
})
export class ChangepassComponent implements OnInit {
  @Input() id: number;

  @Output() fadeOutEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  public mismatch: boolean = false;
  public user: any;
  public message: string;
  public messageChangePass: boolean = false;
  public formChangePass: boolean = true;

  showPassword: boolean = false;
  showPasswordConfirm: boolean = false;

  changePassForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private changePassService: ChangepassService,
  ) { }

  ngOnInit(): void {

    this.changePassForm = this.formBuilder.group({
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6)])
    })
  }


togglePasswordVisibility() {
  this.showPassword = !this.showPassword;
}

togglePasswordConfirmVisibility() {
  this.showPasswordConfirm = !this.showPassword;
}


  onClick() {
    const button = document.querySelector('.btn-send');
    if (button) {
      button.classList.add('clicked');
      setTimeout(() => {
        button.classList.remove('clicked');
      }, 1000);
    }
  }

  onSubmit() {
    if (this.changePassForm.valid) {
      const password = this.changePassForm.get('password').value;
      const confirmPassword = this.changePassForm.get('confirmPassword').value;

      if (password === confirmPassword) {
        // Las contraseñas coinciden, procede con el envío del formulario
        const payload = {
          id: this.id,
          password_new: this.changePassForm.value.password,
        }
        console.log(payload);

        this.formChangePass = false;

        this.changePassService.resetPassword(payload).subscribe((res: any) => {
          console.log(res.det);
          if(res.cod = 200) {
              this.messageChangePass = true;
              this.message = res.det;
              this.fadeOutEvent.emit();
          } else {
              this.messageChangePass = true;
              this.message = 'Ha ocurrido un error, vuelva a intentarlo en un momento';
              this.fadeOutEvent.emit();
          }
          // this.onLogout();
          console.log(this.message)
        });
      } else {
        // Las contraseñas no coinciden, muestra el mensaje de error
        this.mismatch = true;
      }
    }
  }

  closeChangePass() {
    this.closeModal.emit();
  }

}
