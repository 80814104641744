import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { setting } from "../../../../../setting";


@Component({
  selector: 'usb-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {



  menuHidden = true;
  public getClientsSettingAppAuth: any;
  public sectionFooter: any;
  public routeUploadFile:any;
  isHome: boolean = true;
  constructor(private router: Router) {
    this.getClientsSettingAppAuth = JSON.parse(localStorage.getItem(setting.name));
    this.routeUploadFile =  setting.routeUploadFile;
    this.sectionFooter = JSON.parse(this.getClientsSettingAppAuth.clients_setting_app_auth.section_footer);
/* -----------------  change style and background by path change ---------------------------- */

this.router.events.subscribe(event => {
  if (event instanceof NavigationEnd) {
    if (event.url === '/home') {
      this.isHome = true;
    } else if (event.url === '/select') {
      this.isHome = false;
    }
  }
});
  }

  ngOnInit() {
    this.routeUploadFile =  setting.routeUploadFile;
    console.log("llegando a  section_header getClientsSettingAppAuth", this.getClientsSettingAppAuth);
    this.sectionFooter = JSON.parse(this.getClientsSettingAppAuth.clients_setting_app_auth.section_footer);
    console.log("llegando a section_footer", this.sectionFooter);
  }
}
