import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SignupService } from '../signup.service';
import { setting } from '../../../../../setting';
import { ToastrService } from "ngx-toastr";
declare var jquery:any;
declare var $:any;

@Component({
	selector: 'hotels-form',
	templateUrl: './hotels.component.html',
	styleUrls: ['./hotels.component.scss'],
	providers: [SignupService]
})

export class HotelComponent implements OnInit{
	public load : boolean;
	public form : FormGroup;
	public countries : string[];
	public list : string[];
  public getClientsSettingAppAuth: any;

	constructor(
		private _signupService: SignupService,
		private toastr: ToastrService,
    	private fb : FormBuilder
	){
    this.getClientsSettingAppAuth = JSON.parse(localStorage.getItem(setting.name));
		this.load = false;
		this.form = this.fb.group({
			Youaretype: [3, [Validators.required] ],
			Hotel : ['', [Validators.required, Validators.minLength(2), Validators.maxLength(60)] ],
			City: ['', [Validators.required, Validators.maxLength(50)] ],
			Country: ['', [Validators.required,Validators.maxLength(50)] ],
			PhoneNumber: ['', [Validators.required, Validators.pattern('[+]{1}[0-9]{10,16}')]],
			Contact: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(60)] ],
			EmailContact: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
			Comments: ['', [Validators.maxLength(200)] ],
			ApiHas: [''],
			ApiNeed: ['']
	    });

	    this.countries = [
	    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Aruba", "Australia",  "Austria", "Azerbaijan",
	    "Bahamas", "Bahrain",  "Bangladesh",  "Barbados",  "Belarus", "Belgium", "Belize", "Benin", "Bermuda", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi",
	    "Cambodia", "Cameroon", "Canada", "Cape Verde", "Central African Republic", "Chad", "Chile", 'Cook Islands', "Colombia", "Comoros", "Costa Rica", "Côte d'Ivoire", "Croatia", "Cuba", "Czech Republic",
	    "Democratic Republic of the Congo" , "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Democratic Republic of Congo",
	    "East Timor", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Ethiopia",
	    "Federated States of Micronesia", "Fiji", "Finland", "France", "Gabon", "The Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hungary",
	    "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy", "Ivory Coast", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Kuwait", "Kyrgyzstan",
	    "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico","Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco","Mozambique","Myanmar",
	    "Namibia", "Nauru", "Nepal","Netherlands","New Zealand","Nicaragua","Niger","Nigeria","Niue","North Korea","Norway", "Oman", "Pakistan","Palau","Panama", "Papua New Guinea", "Paraguay","People's Republic of China", "Peru","Philippines","Poland","Portugal",
	    "Qatar", "Republic of Macedonia", "Republic of the Congo", "Republic of Cyprus", "Romania", "Russia","Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", "São Tomé and Príncipe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Korea",
	    "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Swaziland", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates","United Kingdom", "United States", "Uruguay", "Uzbekistan",
	    "Vanuatu","Venezuela","Vietnam", "Yemen", "Zambia", "Zimbabwe"
	    ];
	}

	ngOnInit () {

		this.list = ["Hotelbed", "Hoteldo", "Hotelpro", "Tourico", "Despegar", "GTA", "Miki Travel", "Restel", "My Beds", "Bonotel", "Keytel", "Kuoni", 'Juniper', "Nemo", "Expedia", "Booking.com", "Airbnb", "Flypkey"];
		this.list.sort();

		$('#api-select-1').select2({
			placeholder: "Choose a name from this list or insert one",
			tags: this.list,
            tokenSeparators: [","],
            width: "100%",
            allowClear: true

		});

		$('#api-select-2').select2({
		    placeholder: "Choose a name from this list or insert one",
			tags: this.list,
            tokenSeparators: [","],
            allowClear: true,
            width: '100%'

		});


		$('input.select2-search__field').css({width: '280px'});
	}

	onSubmit() {
		this.load = true; // loading screen
		let aux = $("#api-select-1").select2("val");
		let aux2 = $("#api-select-2").select2("val");
		var string1 = "";
		var string2 = "";
		for(let i=0; i < aux.length; i++) {
			string1 = string1 + aux[i] + ','
		}

		for(let i=0; i < aux2.length; i++) {
			string2 = string2 + aux[i] + ','
		}

		string1 = string1.slice(0, -1);
		string2 = string2.slice(0, -1);

		this.form.value.ApiHas = string1;
		this.form.value.ApiNeed = string2;
		console.log("se presiono enviar");
		this._signupService.signup(this.form.value).subscribe(
			(data:any) => {
				this.load = false;
				console.log("llegando data");
				if(data.res == 1) {	
					this.toastr.success(data.det);
					this.resetform();
				}else {
				this.toastr.error(data.det,'Error');
				}
			},
			error => {
				this.load = false;
				this.toastr.error(JSON.parse(error._body).det,'Error');
			}
		);
	}

	resetform(){
		this.form.setValue({
			Youaretype: 3,
			Hotel: '',
			City: '',
			Country: '',
			PhoneNumber: '',
			Contact: '',
			EmailContact: '',
			Comments: '',
			ApiHas: '',
			ApiNeed: ''
		});
	}


}
