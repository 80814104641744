	<!-- LOGIN FORM  -->
	<div class="container" *ngIf="activeform.login" class="white">
	  <h3 class="text-left title pb-1 text-uppercase"> ENTRAR</h3> <!--ACA-->
    <!-- <h3 class="text-left title pb-1 text-uppercase"> LOGIN</h3> -->
	  <form [formGroup]="loginForm" (ngSubmit)="onLogin();loginForm.reset();" novalidate>
	    <div class="container">
	      <div class="row" *ngIf="!load">
	        <div class="col-12 col-md-4 p-0">
	          <input id="email" class="form-control" placeholder="e.g: calrissian@domain.com" type="email"
	            formControlName="email">
	          <!-- ERRORS -->
	          <div
	            *ngIf="loginForm.controls.email.invalid && (loginForm.controls.email.dirty || loginForm.controls.email.touched)">
	            <span class="s-sm booking" *ngIf="loginForm.controls.email.errors.required">
	              <!-- Por favor, inserte un correo electrónico. -->
                Please insert a valid email.
	            </span>
	            <span class="s-sm booking" *ngIf="loginForm.controls.email.errors.pattern">
	              <!-- Por favor, inserte un correo electrónico válido. -->
                Please insert a valid email.
	            </span>
	          </div>
	        </div>
	        <div class="col-12 col-md-4 pl-2">
            <!-- <div class="password-input" style="position: relative;"> -->
              <input
                class="form-control"
                placeholder="e.g: **********"
                [type]="showPassword ? 'text' : 'password'"
                name="password"
                id="password"
                formControlName="password"
                required
              >
              <button type="button" class="password-toggle-btn"
                      (click)="togglePasswordVisibility()"
                      style="  position: absolute; top: 14%; left: 83%; border: 0px; background: transparent;">
                <i class="fa" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
              </button>
            <!-- </div> -->
	          <!-- <input id="password" class="form-control" placeholder="e.g: **********" type="password"
	            formControlName="password"> -->
	          <!-- ERRORS -->
	          <div
	            *ngIf="loginForm.controls.password.invalid && (loginForm.controls.password.dirty || loginForm.controls.password.touched)">
	            <span class="s-sm booking" *ngIf="loginForm.controls.password.errors.required">
	              <!-- Por favor, inserte una contraseña. -->
                Por favor, inserte una contraseña.
	            </span>
	            <span class="s-sm booking" *ngIf="loginForm.controls.password.errors.minlength">
	              <!-- La contraseña debe tener al menos 6 caracteres. -->
                The password must be at least 6 characters.
	            </span>
	          </div>
	        </div>
	        <div class="col-12 col-md-4 text-right">
	          <input class="btn-button s-l" type="submit" value="LOGIN" [disabled]="!loginForm.valid"
	            [ngStyle]="{'background-color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}"> <!--aca-->
	        </div>
	      </div>
	    </div>
	    <div *ngIf="load">
	      <div align="center">
	        <br>
	        <br>
	        <!-- <p class="s-m"> Cargando... </p> -->
          <p class="s-m"> Loading... </p>
	      </div>
	    </div>
	  </form>
	  <p class="s-sm pt-2" align="left">
	    <span class="link-booking f-bold f-underline pr-4" (click)="callRecoverForm();">¿Olvidaste tu contraseña?</span> <!--ACA-->
      <!-- <span class="link-booking f-bold f-underline pr-4" (click)="callRecoverForm();">Did you forget your password?</span> -->
	    <!-- <span class="link-booking" style="font-size: 19px;"> | </span> <a pageScroll data-toggle="modal" data-target="#modalJoinus"
	      class="link-booking f-bold f-underline white pl-4"> Do you want to register? </a> -->
        <span class="link-booking" style="font-size: 19px;"> | </span> <a pageScroll data-toggle="modal" data-target="#modalJoinus"
	      class="link-booking f-bold f-underline white pl-4"> ¿Quieres registrarte? </a> <!--aca-->

	  </p>

	</div>
	<!-- RECOVER PASSWORD FORM  -->

	<div *ngIf="!activeform.login" class="white">
	<h3 class="s-xl pt-2" style="font-weight: 800; font-size: 18px;"> ¿Olvidaste tu contraseña? </h3>  <!--  aca -->
    <!-- <h3 class="s-xl pt-2" style="font-weight: 800; font-size: 18px;"> Did you forget your password? </h3> -->
	  <resetpass (finished)="callLoginForm()"> </resetpass>
	  <p class="s-sm font-back" style="margin-top: 15px; padding-left: 845px !important" (click)="callLoginForm();">
	    <&nbsp;Atras </p> </div> <ng-template #ModalSelectAgency>
	      <usb-select-agency-modal [modalRoomRef]="modalRef" [arrayAgencyUser]="agencysUser" [_data]="data">
	      </usb-select-agency-modal>
	      </ng-template>
